<template>
    <div class="kteventsection_container">
        <figure class="dp_flex flex_justify_center">
            <img class="ktcollaboraplayz_img" src="/media/img/collaboration/ktaplayzcollabor.png" alt="KT x APLAYZ" />
        </figure>

        <figure class="dp_flex flex_justify_center kteventsection1_text_img_wrap">
            <img
                class="kteventsection1_text_img"
                src="/media/img/collaboration/kteventsection1_text.png"
                alt="Galaxy Z Fold6 | Z Filp6"
            />
        </figure>
        <h3 class="kteventsection1_text">
            사전 예약 EVENT
        </h3>
        <figure class="dp_flex flex_justify_center kteventsection1_text2_img_wrap">
            <img
                class="kteventsection1_text2_img"
                src="/media/img/collaboration/kteventsection1_text2.png"
                alt="Galaxt AI is here"
            />
        </figure>

        <figure class="dp_flex flex_justify_center ktproduct1_img_wrap">
            <img class="ktproduct1_img" src="/media/img/collaboration/ktproduct1.png" alt="KT 상품" />
        </figure>
    </div>
</template>
<script>
export default {
  name: 'KtEventSection1',

  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style src="@/assets/css/collaboration/collaborfont.css"></style>
<style scoped src="@/assets/css/collaboration/kteventsection1.css"></style>
